<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="listTab">
        <ion-tab-button tab="favoris" href="/tabs/favoris">
          <ion-icon :icon="star" />
          <ion-label>{{ $t('favorites') }}</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="match" href="/tabs/match">
          <ion-icon :icon="basketball" />
          <ion-label>{{ $t('matches') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="competition" href="/tabs/competition">
          <ion-icon :icon="podium" />
          <ion-label>{{ $t('competitions') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="clubs" href="/tabs/clubs">
          <ion-icon :icon="bookmarks" />
          <ion-label>{{ $t('clubs') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="player" href="/tabs/players">
          <ion-icon :icon="people" />
          <ion-label>{{ $t('players') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <div class="popPwaInstall" v-if="showInstallPwa && !CheckInstallPwa && !displayModePwa">

      <p>{{ $t('msg_pwa') }}</p>
      <div class="btnPwa">
        <ion-button fill="clear" class="btnAddPwa" @click="btnAddPwa()">{{ $t('install') }}</ion-button>
        <ion-button fill="clear" class="closeBtn" @click="closePwaInstall">{{ $t('not_now') }}</ion-button>
      </div>

    </div>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet,IonButton } from '@ionic/vue';
import { star,basketball,people,podium,bookmarks  } from 'ionicons/icons';
export default {
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet,IonButton  },
  setup() {
    return {
      basketball,
      star,
      people,
      podium,

    bookmarks
    }
  },
  data() {
    return {
      showInstallPwa: false,
      CheckInstallPwa: false,
      deferredPrompt: null,
      displayModePwa: false,
    }
  },
  methods: {
    btnNotifPush() {
          window.addEventListener('beforeinstallprompt', (e) => {
          // Prevent the mini-infobar from appearing on mobile
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
          // Update UI notify the user they can install the PWA
            this.showInstallPwa = true;
          // Optionally, send analytics event that PWA install promo was shown.
        });
      window.addEventListener('appinstalled', () => {
        // Hide the app-provided install promotion
        this.CheckInstallPwa = true;
        // Clear the deferredPrompt so it can be garbage collected
        this.deferredPrompt = null;
        // Optionally, send analytics event to indicate successful install
      });
      window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
        this.displayModePwa = evt.matches;
      });
    },
    btnAddPwa() {
      // hide our user interface that shows our A2HS button
      this.showInstallPwa = false;
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice
        .then(() => {
          // if (choiceResult.outcome === 'accepted') {
          //   console.log('User accepted the A2HS prompt');
          // } else {
          //   console.log('User dismissed the A2HS prompt');
          // }
          this.deferredPrompt = null;
        });
    },
    closePwaInstall() {
      this.showInstallPwa = false;
    }

  },
  mounted() {
    this.btnNotifPush();
  }
}
</script>

<style scoped>
.listTab ion-label{
  text-transform: capitalize;
}
</style>

<style>
.popPwaInstall{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--ion-color-tertiary);
  padding: 10px;
  text-align: center;
  z-index: 999;
  min-height: 130px;
}
.popPwaInstall p{
  color: white;
}
.btnPwa{
  /*display: flex;*/
  /*flex-direction: row-reverse;*/
}
.btnPwa .btnAddPwa{
  background-color: white;
  color: var(--ion-color-tertiary);
  padding: 10px;
  margin-top: 10px;
  width: 200px;
  height: 50px;
  border-radius: 100px;
  margin-bottom: 15px;

}
.btnPwa .closeBtn{
  color: #cccccc;
}
</style>