import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/favoris',
  },
  {
    name:'matchDetails',
    path: '/matchDetails/:id/',
    component: () => import('@/views/MatchDetails.vue'),
    meta: {
      title: 'matchDetails'
    }
  },
  {
    name:'competitionDetails',
    path: '/competitionDetails/:id/',
    component: () => import('@/views/CompetitionDetails.vue')
    ,
    meta: {
      title: 'competitionDetails'
    }
  },
  {
    name:'tournamentDetails',
    path: '/tournamentDetails/:id/',
    component: () => import('@/views/TournamentDetails.vue'),
    meta: {
      title: 'tournamentDetails'
    }
  },
  {
    name:'playerDetails',
    path: '/playerDetails/:id/',
    component: () => import('@/views/PlayerDetails.vue'),
    meta: {
      title: 'playerDetails'
    }
  },
  {
    name:'teamDetails',
    path: '/teamDetails/:id/',
    component: () => import('@/views/TeamDetails.vue'),
    meta: {
      title: 'teamDetails'
    }
  },
  {
    name:'clubDetails',
    path: '/clubDetails/:id/',
    component: () => import('@/views/ClubDetails.vue'),
    meta: {
      title: 'clubDetails'
    }
  },
  {
    name:'StartConnect',
    path: '/connect/',
    component: () => import('@/views/StartConnect.vue'),
    meta: {
      title: 'startConnect'
    }
  },
  {
    name:'params',
    path: '/params/',
    component: () => import('@/views/ParamsPage.vue'),
    meta: {
      title: 'params'
    }
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/favoris'
      },
      {
        path: 'favoris',
        component: () => import('@/views/FavorisPage.vue'),
        meta: {
          title: 'favoris'
        }
      },
      {
        path: 'match',
        component: () => import('@/views/MatchPage.vue'),
        meta: {
          title: 'match'
        }
      },
      {
        path: 'competition',
        component: () => import('@/views/CompetitionPage.vue'),
        meta: {
          title: 'competition'
        }
      },
      {
        path: 'clubs',
        component: () => import('@/views/ClubsPage.vue'),
        meta: {
          title: 'clubs'
        }
      },
      {
        path: 'players',
        component: () => import('@/views/PlayersPage.vue'),
        meta: {
          title: 'players'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
// If the route has a title, set it as the page title of the document/page
  if (typeof(title) === 'string') {
    document.title = title
  }
  // Continue resolving the route
  next()
})
export default router
