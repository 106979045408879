import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './registerServiceWorker';
import { IonicVue } from '@ionic/vue';
import { createI18n } from 'vue-i18n';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';





// import firebase
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore, setDoc, updateDoc, arrayUnion, persistentLocalCache, initializeFirestore } from "firebase/firestore";
import {getAuth,onAuthStateChanged} from "firebase/auth";
import { getMessaging,getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyBmvAjdMgV9xKHIPtBdl4Ur__Dbp-Sutps",
  authDomain: "floorball-view.firebaseapp.com",
  projectId: "floorball-view",
  storageBucket: "floorball-view.appspot.com",
  messagingSenderId: "68223172863",
  appId: "1:68223172863:web:d0d5d71bccea7bad36ccca",
  measurementId: "G-MCMRMG9QXD"
};


// Initialize Firebase
const apps = initializeApp(firebaseConfig);
initializeFirestore(apps, {
  localCache: persistentLocalCache()
});
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(apps);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(apps);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(apps);

export const analytics = getAnalytics(apps);



//demande de notifications web
async function requestPermission(tokenNotification: Array<any>,uid: string) {
  console.log('Requesting permission...');
  if (isIOS()) {
    syncToken(tokenNotification, uid);
  }
  else {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
       
       syncToken(tokenNotification,uid);
      }
      // else {
      // //   // await store.dispatch("updateNotifications",{function:"SET_NOTIFICATIONS_GLOBAL",value:false})
      //   console.log('Unable to get permission to notify.');
      //  }
    });
  }
}

// TODO(developer): Retrieve an Instance ID token for use with FCM.
// Add the public key generated from the console here.
async function syncToken(tokenNotification: Array<any>, uid: string) {
  await getToken(messaging, { vapidKey: 'BLApQL6dSwnI3XsPgAPuyD-39OBUbW-JkxY7fQrVZ4Uwyhf1V_36EDParKgMdxGscTrY8-7uM2wmAlvhJ3lk1ig' }).then(async (currentToken) => {
    console.log(currentToken)
    if (currentToken) {
      //console.log(currentToken);
      await updateDoc(doc(db, "users", uid), {
        tokenNotification: arrayUnion(currentToken)
      })
    }
    else {
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}

function isIOS() {
  return navigator.userAgent.match(/(iPad|iPhone|iPod)/i);
}

export const defaultLocale = localStorage.getItem('lang') || navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en'

//locales



import fr from './locales/fr.json'
import en from './locales/en.json'
import nl from './locales/nl.json'




export const languages = {
  fr: fr,
  en: en,
  nl: nl
}

const messages = Object.assign(languages)

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages: messages,
})

import axios from 'axios'
import VueAxios from 'vue-axios'



import { createStore } from 'vuex'
export const store = createStore({
  state () {
    return {
      user: {
        loggedIn: false,
        data: null,
      },
      params:{
        notifications:{
          global:false,
            match:{
              global:true,
              type:{
                time:true,goal:true,event:true
              }
            },
            player:{
              global:true,
              type:{
                time:true,goal:true,event:true
              }
            },
            team:{
              global:true,
              type:{
                time:true,goal:true,event:true
              }
            },
            competition:{
              global:true,
              type:{
                time:true,goal:true,event:true
              }
            },

          //match:true,player:true,team:true,competition:true,time:true,goal:true,event:true,
        }
      },
      favoris:{
        match:[],
        player:[],
        team:[],
        competition:[],
      },
    }
  },
  getters: {
    loggedIn(state:any) {
      return state.user.loggedIn
    },
    user (state:any) {
      return state.user
    },
    params (state:any) {
      return state.params
    },
    notifications (state:any) {
        return state.params.notifications
    },
    favoris (state:any) {
      return state.favoris
    },
    tokenNotification (state:any) {
      return state.params.tokenNotifications
    },
    language (state:any) {
      return state.params.language
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_PARAMS_NOTIFICATIONS(state, data) {
      state.params.notifications = data;
    },
    SET_NOTIFICATIONS_GLOBAL(state, data) {
      state.params.notifications.global = data;
    },
    //MATCH
    SET_NOTIFICATIONS_MATCH_GLOBAL(state, data) {
      state.params.notifications.match.global = data;
    },
    SET_NOTIFICATIONS_MATCH_TIME(state, data) {
      state.params.notifications.match.type.time = data;
    },
    SET_NOTIFICATIONS_MATCH_GOAL(state, data) {
      state.params.notifications.match.type.goal = data;
    },
    SET_NOTIFICATIONS_MATCH_EVENT(state, data) {
      state.params.notifications.match.type.event = data;
    },
    //PLAYER
    // SET_NOTIFICATIONS_PLAYER(state, data) {
    //   state.params.notifications.player = data;
    // },
    SET_NOTIFICATIONS_PLAYER_GLOBAL(state, data) {
      state.params.notifications.player.global = data;
    },
    SET_NOTIFICATIONS_PLAYER_TIME(state, data) {
      state.params.notifications.player.type.time = data;
    },
    SET_NOTIFICATIONS_PLAYER_GOAL(state, data) {
      state.params.notifications.player.type.goal = data;
    },
    SET_NOTIFICATIONS_PLAYER_EVENT(state, data) {
      state.params.notifications.player.type.event = data;
    },


    //TEAM
    // SET_NOTIFICATIONS_TEAM(state, data) {
    //     state.params.notifications.team = data;
    // },
    SET_NOTIFICATIONS_TEAM_GLOBAL(state, data) {
      state.params.notifications.team.global = data;
    },
    SET_NOTIFICATIONS_TEAM_TIME(state, data) {
      state.params.notifications.team.type.time = data;
    },
    SET_NOTIFICATIONS_TEAM_GOAL(state, data) {
      state.params.notifications.team.type.goal = data;
    },
    SET_NOTIFICATIONS_TEAM_EVENT(state, data) {
      state.params.notifications.team.type.event = data;
    },



    //COMPETITION
    SET_NOTIFICATIONS_COMPETITION(state, data) {
        state.params.notifications.competition = data;
    },
    SET_NOTIFICATIONS_COMPETITION_GLOBAL(state, data) {
      state.params.notifications.competition.global = data;
    },
    SET_NOTIFICATIONS_COMPETITION_TIME(state, data) {
      state.params.notifications.competition.type.time = data;
    },
    SET_NOTIFICATIONS_COMPETITION_GOAL(state, data) {
      state.params.notifications.competition.type.goal = data;
    },
    SET_NOTIFICATIONS_COMPETITION_EVENT(state, data) {
      state.params.notifications.competition.type.event = data;
    },


    // SET_NOTIFICATIONS_TIME(state, data) {
    //     state.params.notifications.time = data;
    // },
    // SET_NOTIFICATIONS_GOAL(state, data) {
    //     state.params.notifications.goal = data;
    // },
    // SET_NOTIFICATIONS_EVENT(state, data) {
    //     state.params.notifications.event = data;
    // },
    SET_FAVORIS(state, data) {
      state.favoris = data;
    },
    SET_FAVORIS_MATCH(state, data) {
      state.favoris.match.push(data);
    },
    SET_FAVORIS_MATCH_REMOVE(state, data) {
        state.favoris.match = state.favoris.match.filter((item: any) => item !== data);
    },
    SET_FAVORIS_PLAYER(state, data) {
      state.favoris.player.push(data);
    },
    SET_FAVORIS_PLAYER_REMOVE(state, data) {
        state.favoris.player = state.favoris.player.filter((item: any) => item !== data);
    },
    SET_FAVORIS_TEAM(state, data) {
        state.favoris.team.push(data);
    },
    SET_FAVORIS_TEAM_REMOVE(state, data) {
        state.favoris.team = state.favoris.team.filter((item: any) => item !== data);
    },
    SET_FAVORIS_COMPETITION(state, data) {
        state.favoris.competition.push(data);
    },
    SET_FAVORIS_COMPETITION_REMOVE(state, data) {
        state.favoris.competition = state.favoris.competition.filter((item: any) => item !== data);
    },
    // language
    SET_LANGUAGE(state, data) {
      state.params.language = data;
      //change language in i18n
      i18n.global.locale = data;
      localStorage.setItem('lang', data);
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          uid: user.uid,
        });
      } else {
        commit("SET_USER", null);
      }
    },
    fetchParamsNotifications({ commit },notification) {
      commit("SET_PARAMS_NOTIFICATIONS", notification);
    },
    fetchParamsLanguage({ commit },language) {
        commit("SET_LANGUAGE", language);
    },
    updateNotifications({ commit,getters },data) {
      commit(data.function, data.value);
      const paramsNotifiaction = doc(db, 'users',getters.user.data.uid);
      setDoc(paramsNotifiaction, { notifications:getters.notifications} ,{ merge: true });
      //si c'est global et qu'on l'active alors on relance la permission
      if(data.function == 'SET_NOTIFICATIONS_GLOBAL' && data.value){
        requestPermission(tokenNotification,getters.user.data.uid)
      }
    },
    fetchFavoris({ commit },favoris) {
      commit("SET_FAVORIS", favoris);
    },
    updateFavoris({ getters }) {
      const paramsFavoris = doc(db, 'users',getters.user.data.uid);
      setDoc(paramsFavoris, { favoris:getters.favoris} ,{ merge: true });
    },
    checkGlobalNotification({ getters }) {
      return getters.notifications.global;
    },
    updateLanguage({ commit,getters },data) {
      commit("SET_LANGUAGE", data);
      const paramsLanguage = doc(db, 'users',getters.user.data.uid);
      setDoc(paramsLanguage, { language:getters.language} ,{ merge: true });
    }
  }
})
let tokenNotification:Array<any> = [];
onAuthStateChanged(auth,async (user) => {
  await store.dispatch("fetchUser", user);
  if(user){
    const paramsNotifiaction = doc(db, 'users', user.uid);
    const docSnap = await getDoc(paramsNotifiaction);
    if (docSnap.exists()) {
      if(docSnap.data().notifications){
        await store.dispatch("fetchParamsNotifications", docSnap.data().notifications);
      }
      if(docSnap.data().favoris){
        await store.dispatch("fetchFavoris", docSnap.data().favoris);
      }
      if(docSnap.data().language){
        await store.dispatch("fetchParamsLanguage", docSnap.data().language);
      }
      else{
        await store.dispatch('updateLanguage', defaultLocale);
      }

      //permissons notification
      if(await store.dispatch("checkGlobalNotification")){
        tokenNotification = docSnap.data().tokenNotification;
        if(!tokenNotification)
        {
          tokenNotification = [];
        }
        requestPermission(tokenNotification,user.uid);
      }


    }
    // else {
    //   // doc.data() will be undefined in this case
    //   // console.log("No such document!");
    // }
  }
});








const app = createApp(App).use(IonicVue).use(router).use(store).use(VueAxios, axios).use(i18n);


  
router.isReady().then(() => {
  app.mount('#app');
});


