<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  }
});
</script>
<style>
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
/*style pour les pc*/
.ion-page{
  max-width: 1180px;
  margin: 0 auto;
  font-family: "Poppins", Helvetica, Arial,serif;
}
:root {
  --ion-font-family: "Poppins", Helvetica, Arial,serif;
}
.accordion-expanded{
  background: var(--ion-item-background, var(--ion-background-color, #fff));
}


.centerElem{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ion-page{
  background-color: var(--ion-background-color);
}
</style>